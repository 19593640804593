import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  Section,
  BreadCrumb,
  GradientWrapper,
  SectionPageTitle,
} from "../components/Section";
import { ArrowRight, HomeIcon } from "../components/Icons";
import Img from "gatsby-image";
import BreakpointDown from "../components/Media/BreakpointDown";

const PageHeaiding = styled(SectionPageTitle)`
  font-size: 42px;
  ${BreakpointDown.md`
    font-size:30px;
  `}
`;

const BlogContainer = styled.div`
  max-width: 890px;
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
  h3 {
    font-size: 24px;
    line-height: 40px;
  }
`;

const BlogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Card = styled.div`
  display: flex;
  background: transparent;
  flex-direction: column;
  border-bottom: 1px solid #cbd1e2;
  padding-bottom: 25px;
  margin-bottom: 10px;
  ${BreakpointDown.md`
      padding-bottom:20px;
      margin-bottom:20px;
  `}
  &:last-child {
    border-bottom: 0;
    ${BreakpointDown.md`
      padding-bottom:0px;
      margin-bottom:20px;
  `}
  }
`;
const CardImg = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const BlogDate = styled.div`
  background: #223c7e;
  padding: 8px;
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 4;
  color: #fff;
  font-size: 18px;
  max-width: 72px;
  line-height: 20px;
  text-align: center;
  padding: 4px;
  font-family: "Poppins", sans-serif;
`;
const CardBody = styled.div`
  margin-top: 0px;
  ${BreakpointDown.md`
    margin-top:15px; 
 `}
`;
const BlogInfo = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  a {
    color: #121316;
    &:hover {
      color: #f36224;
    }
  }
  span {
    &:first-child {
      &:after {
        content: "";
        width: 1px;
        height: 12px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        margin-right: 3px;
        background: #121316;
      }
    }
  }
`;
const CardDescription = styled.div`
  margin-bottom: 10px;
`;
// const CardTitle = styled.h4`
//   line-height: 38px;
//   margin-bottom: 5px;
// `;
// const CardBtn = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   a {
//     &:first-child {
//       .btn {
//         transform: scaleX(-1);
//         .text {
//           transform: scaleX(-1);
//         }
//       }
//     }
//   }
// `;

function BlogPage(queryData) {
  let pageData = queryData.data.contentfulBlogArticle;
  return (
    <Layout pageName="blog">
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <Section
        bgColor="rgba(203, 209, 226, 0.55)"
        pt="180px"
        xpt="60px"
        pb="20px"
      >
        <BreadCrumb top="75" xtop="15px">
          <div className="container">
            <Link to="/">
              <HomeIcon />
              <ArrowRight />
            </Link>
            <Link to="/blog">
              Knowledge Hub
              <ArrowRight />
            </Link>
            <Link className="line-after-heading">
              {pageData.articleHeading}
            </Link>
            <PageHeaiding mt="40px" mb="30px">
              {pageData.articleHeading}
            </PageHeaiding>
          </div>
        </BreadCrumb>
      </Section>
      <GradientWrapper>
        <Section
          pt="70px"
          pb="120px"
          xpt="40px"
          xpb="100px"
          mpt="30px"
          bgColor="transparent"
        >
          <BlogContainer>
            <BlogWrapper>
              <Card>
                <CardImg>
                  <BlogDate>{pageData.postDate}</BlogDate>
                  <Img
                    fluid={pageData.articleImage.fluid}
                    alt={pageData.articleImage.title}
                  />
                </CardImg>
                <CardBody>
                  <BlogInfo>
                    <span>Author : {pageData.authorName}</span>
                    {pageData.blogCategories.map((category) => {
                      return (
                        <span>
                          <Link to={category.caterogyUrl}>
                            {category.categoryName}
                          </Link>
                        </span>
                      );
                    })}
                  </BlogInfo>
                  <CardDescription
                    dangerouslySetInnerHTML={{
                      __html: pageData.contentSection.childMarkdownRemark.html,
                    }}
                  />
                </CardBody>
              </Card>
            </BlogWrapper>
          </BlogContainer>
        </Section>
      </GradientWrapper>
    </Layout>
  );
}

export default BlogPage;

export const pageQuery = graphql`
  query articleQuery($id: String!) {
    contentfulBlogArticle(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      articleHeading
      articleImage {
        title
        fluid {
          aspectRatio
          base64
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      postDate(formatString: "DD MMM YYYY")
      authorName
      blogCategories {
        categoryName
        caterogyUrl
      }
      contentSection {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
